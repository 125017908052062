body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
    padding: 0.2em;
    padding-right: 1.5em;
    cursor: pointer;
    outline: none;
}

.mySelect {
    padding: 15px !important;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    color: rgb(70, 132, 186);
    font-weight: 700;
    width: 100%;
    background: white;
}

label {
    color: black;
    font-weight: 700;
    font-size: 0.85em;
}

input,
textarea:focus {
    outline: none;
}

input,
textarea {
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(212, 212, 212);
    color: rgb(70, 132, 186);
    font-weight: 700;
}

textarea {
    resize: none;
    background: rgb(255, 255, 255) !important;
    padding: 10px 15px;
}

textarea:disabled {
    background: rgb(243, 243, 243) !important;
}

input {
    background: rgb(255, 255, 255) !important;
}

input::placeholder {
    color: rgb(212, 212, 212);
}

input:focus {
    border: 1px solid rgb(70, 132, 186);
}

textarea::placeholder {
    color: rgb(212, 212, 212);
}
textarea:focus {
    border: 1px solid rgb(70, 132, 186);
}

input:disabled {
    background: rgb(243, 243, 243) !important;
}

select:disabled {
    background: rgb(243, 243, 243) !important;
}

button:disabled {
   opacity: 0.5;
}

.primary-btn {
    background-color: #7f5a83;
    background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%);
}

.primaryButton {
    color: #fff !important;
    padding: 5px 30px !important;
    border-radius: 20px !important;
    margin-right: 10px !important;
    outline: none !important;
    background-color: #396e8b !important;
    border: 1px solid #396e8b !important;
}
.primaryButton:hover {
    background-color: none !important;
}

.noStyleButton {
    background: transparent;
    border: none;
    outline: none !important;
}

.cancelButton {
    color: #396e8b !important;
    padding: 5px 30px !important;
    border-radius: 20px !important;
    margin-right: 10px !important;
    outline: none !important;
    background-color: #fff !important;
    border: 1px solid #396e8b !important;
}

.MuiButton-root {
    min-width: 121px !important;
}
.MuiAutocomplete-paper{
    font-family: 'Mulish', sans-serif !important;
}
.editIcon {
    color: #396e8b;
}
.deleteIcon {
    color:#396e8b;
}
.notesIcon {
    color: rgb(98, 141, 173);
}

.btn-mini-primary {
    // border: 1px solid #283144;
    border: none;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 6px 12px;
    margin-right: 10px;
}
.btn-mini-primary{
    &:not(.disabled):hover {
    border: none;
    background: aliceblue;
}
}
.btn-mini-primary:focus {
    outline: none !important;
}

.btn-mini-info {
    border: none;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 6px 12px;
    margin-right: 10px;
}
.btn-mini-info:hover {
    border: none;
}
.btn-mini-info:focus {
    outline: none !important;
}

.btn-mini-secondary {
    border: none;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    padding: 6px 12px;
}
.btn-mini-secondary {
    &:not(.disabled):hover {
    border: none;
    background: aliceblue;

}
}
.btn-mini-secondary:focus {
    outline: none !important;
}
label {
    margin-bottom: 0 !important;
}
.table-cell {
    padding: 8px 16px !important;
    font-weight: 500 !important;
    max-width: 25rem;
    font-family: 'Mulish', sans-serif !important;
}

.table-header {
    color: #878383;
    white-space: nowrap;
    font-weight: 700 !important;
    font-family: 'Mulish', sans-serif !important;
    text-align: center;
}

.TextField {
    width: 100% !important;
    padding: 10px !important;
    border-radius: 10px !important;
    border: 1px solid rgb(212, 212, 212) !important;
    color: rgb(70, 132, 186) !important;
    font-weight: 700 !important;
    background: rgb(255, 255, 255) !important;
  }
  
.TextField input:focus {
    border: none;
}

.TextField input::placeholder {
    color: #aba0a0;
    }

.TextField .MuiInputBase-root {
    font-size: 0.9rem;
    color: rgb(70, 132, 186) !important;
    font-weight: 700 !important;
}
.mentors{
    overflow: auto;
    height: 53px;
    border-radius: 10px !important;
}

.viewFieldMode {
    padding: 15px 5px !important;
    border-bottom: 1px solid #d4d4d4 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: rgba(51, 51, 51, 0.8) !important;
    font-weight: 700 !important;
}
  
  
.MuiInput-underline:before {
    display: none;
}
.MuiInput-underline:after {
    display: none;
}

.MuiPopover-paper {
    border: 1px solid !important;
}

.MuiPaper-elevation8 {
    box-shadow: none !important;
}

.MuiMenuItem-root, .MuiTypography-h6{
  font-family: 'Mulish', sans-serif !important;
}

.tags-filter {
    padding: 5px !important;
    border-radius: 4px !important;

    @media screen and (max-width: 999px){
        padding: 5px 12px !important;
    }

    height: 42px;
    justify-content: center;
}

.btn-add {
    &:hover {
        background-color: #33c2f8 !important;
    }
}
.input-danger {
    border: 1px solid red !important;
    border-radius: 10px;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #ededed;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1500;
    opacity: 0.5;
}

.spinner-loader {
    max-width: 15rem;
    width: 100%;
    height: auto;
    stroke-linecap: round;
}

circle {
    fill: none;
    stroke-width: 3.5;
    animation-name: preloader;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform-origin: 170px 170px;
    will-change: transform;

    &:nth-of-type(1) {
        stroke-dasharray: 550px;
    }

    &:nth-of-type(2) {
        stroke-dasharray: 500px;
    }

    &:nth-of-type(3) {
        stroke-dasharray: 450px;
    }

    &:nth-of-type(4) {
        stroke-dasharray: 300px;
    }

    @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
            animation-delay: -#{$i * 0.15}s;
        }
    }
}

@keyframes preloader {
    50% {
        transform: rotate(360deg);
    }
}

.main-panel-full {
    width: 100% !important;

    .navbar-container .logo {
        display: block;
    }
}

.main-panel-full::-webkit-scrollbar {
    display: none;
}

.navbar-container .logo {
    display: none;
}
.pointer-disabled {
    pointer-events: none;
}

.skills , .status {
    padding: 7px 20px;
    border-radius: 15px;
    font-weight: 700;
    color: #fff;
    text-align: left !important;
    margin-top: 5px;
    white-space: nowrap;
}

.billable {
    padding: 4px 11px;
    border-radius: 15px;
    font-weight: 700;
    color: #fff;
    text-align: left !important;
    margin-top: 5px;
}

.no-data-para {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    padding: 20px !important;
    text-align: center !important;
}
.form-disabled {
    pointer-events: none;
}

.table-content {
    height: 80vh;
    overflow: auto;
}


.detail-project {
    display: flex;
    align-items: center;
}

.link:link {
    color: blue;
}
  
.link:hover {
    color: blue;
    text-decoration: underline;
}

.error-message {
    color: red;
}


.event-status-icon {
    width:28px;
    float:left;
    margin-right: 15px;
}


.hide-mobile {
    display: block; 

    @media screen and (max-width: 768px){
        display: none;
    }
}
.show-mobile {
    display: none; 

    @media screen and (max-width: 768px){
        display: block;
    }
}
.MuiMenu-paper {
    border: none !important;
}
.MuiMenu-list {
    padding: 0;
    background: #396e8b;
    color: #fff;
}

.link {
    color: rgb(77, 124, 161);
}

.MuiTableFooter-root td {
    position: sticky;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 5;
}

input[type=checkbox]:checked {
    filter: hue-rotate(330deg);
}