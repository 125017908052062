.wrapper{
    position: relative;
    top: 0;
    height: 100vh;
    overflow: hidden;

    &.wrapper-full-page{
        min-height: 100vh;
        height: auto;
    }
}

.sidebar .sidebar-wrapper,
.main-panel{
    overflow: auto;
}

.sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

.sidebar,
.main-panel{
    height: 100%;
    max-height: 100;
}

.sidebar,
.off-canvas-sidebar{
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 280px;
    left: 0;
    z-index: 1031;

    .sidebar-wrapper{
        position: relative;
        height: calc(100vh - 100px);
        overflow: auto;
        width: 260px;
        z-index: 4;
        padding-bottom: 100px;

        @media screen and (max-width: 991px){
          height: auto;
          width: 100%;
        }

        .dropdown .dropdown-backdrop{
          display: none !important;
        }

        .navbar-form{
            border: none;
        }

        > .nav,
        .user .info{
          [data-toggle="collapse"] ~ div > ul > li > a{
              span{
                display: inline-block;
                @extend .animation-transition-general;
              }

              i{
                font-size: 17px;
                line-height: 20px;
                width: 26px;
              }
            }
        }
    }

    .navbar-minimize{
      position: absolute;
      right: 20px;
      top: 2px;
      opacity: 1;

      @extend .animation-transition-general;
    }
    .logo-tim{
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img{
          width: 60px;
          height: 60px;
      }
    }

    .nav{
        margin-top: 20px;
        display: block;

        .caret{
            top: 14px;
            position: absolute;
            right: 10px;
        }

        li{
            > a + div .nav li > a{
                margin-top: 7px;
            }

            > a,
            > a:not([href]):not([tabindex]):focus,
            > a:not([href]):not([tabindex]):hover{
                margin: 13px 0;
                border-radius: 0px 20px 20px 0px;
                color: $white-color;
                display: block;
                text-decoration: none;
                position: relative;
                cursor: pointer;
                font-size: 14px;
                padding: 5px 8px;
                font-family: 'Mulish', sans-serif;
                line-height: $line-height-nav-link;

                @media screen and (max-width: 991px){
                  border-radius: 0;
                }
            }

            &:first-child > a{
                margin: 0;
            }

            &:hover:not(.active) > a,
            &:focus:not(.active) > a{
                background-color: $opacity-1;
            }

            &:hover:not(.active) > a i,
            &:focus:not(.active) > a i{
                color: white;
            }

            &.active > a:not([data-toggle="collapse"]){
                background-color: white;
                box-shadow: $box-shadow;
            }

            &.active > a[data-toggle="collapse"]{
              background-color: $opacity-1;
              box-shadow: none;
              color: #61DAFB;

              i{
                color: $white-color;
              }

              & + div .nav .active a{
                &:after{
                  content: "";
                  position: absolute;
                  background-color: $white-color;
                  border-radius: 30px;
                  box-shadow: $box-shadow;
                  color: $primary-color;
                  height: 44px;
                  width: calc(100% - 5px);
                  top: 0;
                  left: 2px;
                  z-index: 0;
                }
              }
            }
        }

        p{
            margin: 0;
            line-height: 30px;
            position: relative;
            display: block;
            height: auto;
            white-space: nowrap;
            transition: transform 300ms ease 0s, opacity 300ms ease 0s;
        }

        i{
            font-size: 20px;
            float: left;
            margin-right: 12px;
            line-height: 30px;
            width: 34px;
            text-align: center;
            color: $opacity-5;
            position: relative;
        }
    }

    .sidebar-background{
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: center center;

        &:after{
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 100%;
            content: "";
            display: block;
            background: #FFFFFF;
            opacity: 1;
        }
    }

    .logo{
        position: relative;
        padding: $padding-base-vertical $padding-base-horizontal;
        z-index: 4;

        a.logo-mini,
        a.logo-normal{
            @extend .animation-transition-general;
        }

        a.logo-mini{
            opacity: 1;
            float: left;
            width: 34px;
            text-align: center;
            margin-left: 10px;
            margin-right: 12px;
        }

        a.logo-normal{
            display: block;
            opacity: 1;
            @include transform-translate-x(0px);
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: transparent;

        }

        p{
            float: left;
            font-size: 20px;
            margin: 10px 10px;
            color: $white-color;
            line-height: 20px;
            font-family: 'Mulish', sans-serif;
        }

        .simple-text{
            text-transform: uppercase;
            padding: $padding-base-vertical 0;
            display: block;
            white-space: nowrap;
            font-size: $font-size-large;
            color: #5f6d79;
            text-decoration: none;
            font-weight: $font-weight-normal;
            line-height: 30px;
            overflow: hidden;
        }
    }

    .logo-tim{
        border-radius: 50%;
        border: 1px solid #333;
        display: block;
        height: 61px;
        width: 61px;
        float: left;
        overflow: hidden;

        img{
            width: 60px;
            height: 60px;
        }
    }

    &:before,
    &:after{
        display: block;
        content: "";
        opacity: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    &:after{
        @include icon-gradient($default-color);
        z-index: 3;
    }

    &[data-color="blue"]{
      @include sidebar-color($info-color);
    }
    &[data-color="green"]{
      @include sidebar-color($success-color);
    }
    &[data-color="orange"]{
      @include sidebar-color($orange-color);
    }
    &[data-color="red"]{
      @include sidebar-color($danger-color);
    }
    &[data-color="yellow"]{
      @include sidebar-color($warning-color);
    }

}

.visible-on-sidebar-regular{
    display: inline-block !important;
}
.visible-on-sidebar-mini{
    display: none !important;
}

.off-canvas-sidebar{
    .nav {
        > li > a,
        > li > a:hover{
            color: $white-color;
        }

        > li > a:focus{
            background: rgba(200, 200, 200, 0.2);
        }
    }
}


.main-panel{
    position: relative;
    float: right;
    // width: $sidebar-width;
    // width: 100%;
    background-color: $light-gray;
    background-color: #ebecf1;

    @include transition (0.50s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    div > .content{
        padding: 0 30px 30px;
        min-height: calc(100vh - 123px);
        margin-top: -30px;
    }

    > .navbar{
        margin-bottom: 0;
    }


    .header{
        margin-bottom: 50px;
    }
}


.perfect-scrollbar-on{
  .sidebar,
  .main-panel{
      height: 100%;
      max-height: 100%;
  }
}


@media (min-width: 991px) {
    .sidebar,
    .main-panel,
    .sidebar-wrapper{
        -webkit-transition-property: top,bottom,width;
        transition-property: top,bottom, width;
        -webkit-transition-duration: .2s,.2s, .35s;
        transition-duration: .2s,.2s, .35s;
        -webkit-transition-timing-function: linear,linear,ease;
        transition-timing-function: linear,linear,ease;
        -webkit-overflow-scrolling: touch;
    }

}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  position: relative;
  @include panel-gradient($black-color);

  .header{
    .title{
      color: $white-color;
    }
    .category{
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a{
        color: $white-color;
      }
    }
  }
}

.panel-header-sm{
  height: 135px;
}

.panel-header-lg{
  height: 380px
}

.sidebar,
.main-panel,
.sidebar-wrapper{
  -webkit-overflow-scrolling: touch;
}
